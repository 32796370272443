header {
  background-color: #f2f2f2;
  padding: 10px 0;
  text-align: center;
}
header h1 {
  margin: 0;
  font-size: 36px;
}
header p {
  margin: 0;
  font-size: 16px;
}
.news-container {
  width: 60%;
  margin: 20px auto;
}
.news-item {
  border-bottom: 1px solid #e2e2e2;
  padding: 20px 0;
}
.news-item:last-child {
  border-bottom: none;
}
.news-logo {
  float: left;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

a {
  color: black; /* Set the text color to black */
  text-decoration: none; /* Remove the underline */
  font-weight: bold; /* Make the text bold */
}

.news-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.news-text {
  font-size: 16px;
  margin: 5px 0;
}
.news-date {
  font-size: 14px;
  color: #666;
}
@media (max-width: 768px) {
  .news-container {
      width: 90%;
  }
}